import React from "react";
import Agent from "./agent.jsx";
export const GetAllJobTasks = async (token) => {
    const { requests } = Agent();
    return await requests.get(`/TimeEntry/GetJobTasks`, token);
}
export const LogTime = async (param, token) => {
    const { requests } = Agent();
    return await requests.post(`/TimeEntry/InsertTime`, param, token);
}
export const GetMonthlyCalendar = async (selectedMonth, id, token) => {
    const { requests } = Agent();
    var attribute = {
        selectedMonth: selectedMonth,
        employee: id
    };
    return await requests.getAtt('/TimeEntry/GetMonthlyCalendar', attribute, token);
}
