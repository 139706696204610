import React from "react";
import "./changePassword.css";
import useChangePassword from '../../hooks/useChangePassword';
import userIcon from "../../assets/images/login/user.png";
import passwordIcon from "../../assets/images/login/password.png";
const ChangePassowrd = () => {
    const {
        currentPassword, newPassword, confirmPassword, passwordError,
        username, onNewPasswordChange, onConfirmPassword, onCurrentPasswordChange,
        onFormClick, onClear

    } = useChangePassword();

    return (
        <div className="login">
            <div className="container">
                <div className="d-flex justify-content-center sign-in-panel">
                    <div className="card">
                        <div className="card-header">
                            <h3>{username}</h3>
                            <h6>Please reset your password for enhanced security measures</h6>
                            <h6>{passwordError}</h6>
                        </div>
                        <div className="card-body">
                            <form>
                                <div className="input-group form-group mb-5">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><img src={passwordIcon} />Current Password</span>
                                    </div>
                                    <input type="password" className="form-control" placeholder="Current Password"
                                        value={currentPassword}
                                        onChange={onCurrentPasswordChange} />

                                </div>
                                <div className="input-group form-group mb-2">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><img src={passwordIcon} />New Password</span>
                                    </div>
                                    <input type="password" className="form-control" placeholder="password"
                                        value={newPassword}
                                        onChange={onNewPasswordChange} />
                                </div>
                                <div className="input-group form-group mb-2">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><img src={passwordIcon} />Confirm Password</span>
                                    </div>
                                    <input type="password" className="form-control" placeholder="password"
                                        value={confirmPassword}
                                        onChange={onConfirmPassword} />
                                </div>

                                <div className="form-group">
                                    <button type="button" className="btn float-right login_btn"
                                        onClick={onFormClick}>Save</button>
                                </div>
                            </form>
                        </div>
                        <div className="card-footer">


                        </div>
                    </div>
                </div>
            </div></div>

    )

}
export default ChangePassowrd;