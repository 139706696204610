import React, { useState } from 'react';
import "react-widgets/styles.css";
import "./add-log-time.css";
import Combobox from "react-widgets/Combobox";
import Multiselect from "react-widgets/Multiselect";
import DatePicker from "react-widgets/DatePicker";
import useAddLogTime from '../../hooks/log-time/useAddLogTime';
import JobTasksModal from './job-tasks-Modal';

const AddLogTime = () => {
    const { clients, selectedClient, displayProjects, selectedProject,
        isTeamLeader, workDate, startTime, endTime, jobTasks, addNewJobTasks, newJobTask, numberOfHours, teamLeaders, selectedTteamLeader, showAlarm, alarmMessage,
        startTimes, endTimes, showJobTasksModal, allWorkers, selectedWorkers, takeBreak, note, setTakeBreak, handleNoteChange, setSelectedClient, setSelectedWorkers,
        setSelectedProject, setIsTeamLeader, setWorkDate, handleStartTimeChange, onJobTasksSelected, handleNewJobTaskChange, setSelectedTteamLeader,
        handleEndTimeChange, setShowJobTasksModal, onClearJobTasks, onhandleClear, onSubmitClick } = useAddLogTime();
    return (
        <div>
            <h2>Time Logging</h2>
            {showAlarm && <div className={`alert alert-${alarmMessage.type}`} role="alert">
                {alarmMessage.text}
            </div>}
            {
                isTeamLeader && <JobTasksModal
                    showModal={showJobTasksModal}
                    joblist={jobTasks}
                    onJobTaskSelected={onJobTasksSelected}
                    onSaveAndClose={setShowJobTasksModal}
                    addNewJobTasks={addNewJobTasks}
                    newJobTask={newJobTask}
                    onCloseModalClick={onClearJobTasks}
                    handleNewJobTaskChange={handleNewJobTaskChange} />
            }
            <div className="row">
                <div className="col-12 form-control-container">
                    <label>
                        Client:
                    </label>
                    <Combobox
                        data={clients}
                        dataKey='id'
                        textField='name'
                        value={selectedClient}
                        defaultValue={selectedClient}
                        containerClassName={" form-combobox"}
                        onChange={(value) => setSelectedClient(value.id)}
                    />
                </div>
                <div className="col-12 form-control-container">
                    <label>
                        Project:
                    </label>
                    <Combobox
                        data={displayProjects}
                        dataKey='id'
                        textField='name'
                        value={selectedProject}
                        defaultValue={selectedProject}
                        containerClassName={" form-combobox"}
                        onChange={(value) => setSelectedProject(value.id)}
                    />
                </div>
                <div className="col-12 form-control-container">
                    <label>
                        Team leader:
                    </label>
                    <Combobox
                        data={teamLeaders}
                        dataKey='id'
                        textField='name'
                        value={selectedTteamLeader}
                        defaultValue={selectedTteamLeader}
                        containerClassName={" form-combobox"}
                        onChange={(value) => setSelectedTteamLeader(value.id)}
                    />
                </div>
                {isTeamLeader &&
                    <> <div className="col-12 form-control-container">
                        <label>
                            Select your team:
                        </label>
                        <Multiselect
                            data={allWorkers}
                            value={selectedWorkers}
                            onChange={value => setSelectedWorkers(value)}
                            containerClassName={" form-combobox"}
                            dataKey='id'
                            textField='name'

                        />
                    </div>

                        <div className="col-12 form-control-container mt-2">
                            <button type="button" className='btn btn-success' onClick={() => setShowJobTasksModal(true)}>
                                Add Job tasks
                            </button>
                        </div></>
                }
                <div className="col-12 form-control-container">
                    <label>
                        Work Date:
                    </label>
                    <DatePicker
                        containerClassName={" form-combobox "}
                        defaultValue={new Date()}
                        valueEditFormat={{ dateStyle: "short" }}
                        valueDisplayFormat={{ dateStyle: "short" }}
                        value={workDate}
                        onChange={value => setWorkDate(value)}
                    />
                </div>
                <div className="col-12 form-control-container">
                    <label>
                        Start Time:
                    </label>
                    <Combobox
                        data={startTimes}
                        value={startTime}
                        defaultValue={startTime}
                        containerClassName={" form-combobox"}
                        onChange={(value) => handleStartTimeChange(value)}
                    />
                </div>
                <div className="col-12 form-control-container">
                    <label>
                        End Time:
                    </label>
                    <Combobox
                        data={endTimes}
                        value={endTime}
                        defaultValue={endTime}
                        containerClassName={" form-combobox"}
                        onChange={(value) => handleEndTimeChange(value)}

                    />
                </div>
                <div className="col-12">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={takeBreak}
                            onChange={() => setTakeBreak(!takeBreak)} />
                        <label className="form-check-label" for="flexSwitchCheckDefault">I take a break</label>
                    </div>
                </div>
                <div className="col-12 form-control-container">

                    <label>
                        {numberOfHours} hrs
                    </label>
                </div>                <div className="col-12 form-control-container">

                    <textarea
                        type="text"
                        className="form-control mb-3"
                        value={note}
                        onChange={(e) => handleNoteChange(e)}
                        placeholder="Note..."
                        rows="5"
                        cols="50"
                    /></div>
                <div className="d-flex justify-content-end">
                    <button type="submit" className='btn btn-danger me-2' onClick={onhandleClear}>Clear</button>
                    <button type="submit" className='btn btn-primary' onClick={onSubmitClick}>Submit</button>
                </div>
            </div>
        </div>
    );
};
export default AddLogTime;