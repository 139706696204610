import React from "react";
import { NavLink } from "react-router-dom";
import DropdownList from "react-widgets/DropdownList";
import useNavSideBar from "../hooks/useNavSideBar";
import './NavSideBar.css';
import Logo from '../assets/images/SB-logo.png';
const NavSideBar = () => {
    const { userName, activeItem, monthList, selectedMonth, profileDropDown, setProfileDropDown, onlogoutClick, setSelectedMonth, setOpenDropdown, openDropdown, showMenuBar, setShowMenuBar } = useNavSideBar();
    return (
        <nav className="navbar navbar-expand-lg ">
            <div className="container-fluid">

                <a className="navbar-brand" href="#">
                    <img className="d-inline-block align-text-top logo-img pe-2" src={Logo} alt="" />
                    <strong> SB Bouwwerken</strong></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => setShowMenuBar(!showMenuBar)}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`collapse navbar-collapse ${showMenuBar ? 'show' : ''}`} id="navbarSupportedContent">
                    <ul className="navbar-nav mb-2 mb-lg-0 w-100">
                        <li className="nav-item pe-3">
                            <NavLink className="nav-link" to="Add-log-time">Add-log-time</NavLink >
                        </li>
                        <li className="nav-item dropdown ">
                            <div class="btn-group">
                                <button class="btn btn-transparent  dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => setOpenDropdown(!openDropdown)}>
                                    {selectedMonth}
                                </button> </div>
                            <ul className={`dropdown-menu ${openDropdown ? 'show' : ''}`}>
                                {monthList.map((month) => (
                                    <li className="dropdown-item" onClick={() => {
                                        setSelectedMonth(month);
                                        setOpenDropdown(false);
                                    }}>
                                        <NavLink className="nav-link  " to={`monthly-calendar/${month.replace(" ", '-')}`}>{month}</NavLink >
                                    </li>
                                ))}
                            </ul>

                        </li>
                        <li className="nav-item dropdown ms-lg-auto">
                            <div className="btn-group">
                                <button className="btn btn-transparent  dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => setProfileDropDown(!profileDropDown)}>
                                    {userName}
                                </button> </div>
                            <ul className={`dropdown-menu ${profileDropDown ? 'show' : ''}`}>

                                <li className="dropdown-item" onClick={() => {
                                    onlogoutClick();
                                }}>
                                    <NavLink className="nav-link  " to="/login">Logout</NavLink >
                                </li>

                            </ul>

                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};
export default NavSideBar;
