import { useState } from "react";
import { useUserContext } from "./useUserContext";
import { Login } from "../api/accountApi";
import { useNavigate } from "react-router-dom";
const useLogin = () => {
    const { state, dispatch } = useUserContext();
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [error, setError] = useState('');
    const history = useNavigate();
    const handleSubmit = async () => {
        var userInfo = await Login({ Username: username, Password: password });

        if (userInfo) {
            if (userInfo.data) {
                setError(userInfo.data.error);
            } else {
                if (userInfo.passwordUpdated) {
                    localStorage.setItem('token', userInfo.token);
                    localStorage.setItem('username', userInfo.username);
                    localStorage.setItem('employee', userInfo.id);
                    dispatch({
                        type: 'LOGIN_SUCCESS',
                        user: userInfo.username || null,
                        token: userInfo.token || null,
                        id: userInfo.id || null,
                        updatePassword: false
                    });
                    var monthNames = ["January", "February", "March", "April", "May", "June",
                        "July", "August", "September", "October", "November", "December"];
                    let nbrOfMonth = monthNames[new Date().getMonth()] + '-' + new Date().getFullYear();
                    history("/" + (state.redirect ? state.redirect : "monthly-calendar/" + nbrOfMonth));
                } else {
                    dispatch({
                        type: 'CHANGEPASSWORD',
                        user: userInfo.username || null,
                        token: userInfo.token || null,
                        id: userInfo.id || null,
                        updatePassword: true

                    });
                    history("/change-password/");
                }

            }
        }
    }
    return {
        username, password, error,
        onFormClick: async () => {
            handleSubmit();

        },
        onUserChange: (e) => {
            e.preventDefault();
            setUsername(e.target.value);
        },
        onPasswordChange: (e) => {
            e.preventDefault();
            setPassword(e.target.value);
        },
        onClear: () => {
            setPassword('');
            setUsername('');
        },

    }
}


export default useLogin;