import React from "react";
import Agent from "./agent.jsx";


export const GetJobTitles = async (token) => {
    const { requests } = Agent();
    return await requests.get('/JobTitle/GetJobTitles', token);
}
export const GetAllTeamLeader = async (token) => {
    const { requests } = Agent();
    return await requests.get('/Employee/GetAllTeamLeaders', token);
}
export const GetAllWorkers = async (token) => {
    const { requests } = Agent();
    return await requests.get('/Employee/GetAllWorkers', token);
}