import React from "react";
import Agent from "./agent.jsx";
export const Login = async (account) => {
    const { requests } = Agent();
    return await requests.loginPost('/Account/login', account);
}
export const ChangePassword = async (account, token) => {
    const { requests } = Agent();
    return await requests.put('/Account/ChangePassword', account, token);
}
export const validateToken = async (token) => {
    const { requests } = Agent();
    return await requests.post('/Account/validate-token', { token });
};