import { Outlet, Navigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import { useUserContext } from "./hooks/useUserContext";

import { validateToken } from "./api/accountApi";
const PrivateRoutes = () => {
    const { state, dispatch } = useUserContext();
    const [loading, setLoading] = useState(true); // State to track loading state
    const [authenticated, setAuthenticated] = useState(false); // State to track authentication status

    useEffect(() => {
        const checkToken = async () => {
            const token = localStorage.getItem('token');

            if (state.token != null) {
                setAuthenticated(true);
                setLoading(false);
            } else {
                if (token) {
                    const isValid = await validateToken(token); // Replace with your actual validation function

                    if (!isValid.data) {

                        localStorage.removeItem('token');
                        localStorage.removeItem('username');
                        setAuthenticated(false);
                        setLoading(false);
                        dispatch({ type: 'LOGOUT' });
                    } else {

                        setAuthenticated(isValid.data);
                        setLoading(false);
                        dispatch({
                            type: 'LOGIN_SUCCESS', token: token, user: localStorage.getItem('username'), id: localStorage.getItem('employee'),
                            updatePassword: false
                        });
                    }
                } else {
                    setAuthenticated(false);
                    setLoading(false);
                    dispatch({ type: 'LOGOUT' });
                }
            }
        }


        checkToken();
    });

    if (loading) {
        console.log(loading);
        return <div>Loading...</div>; // Optional: Show a loading indicator while checking token
    }
    return (

        authenticated ? <Outlet />
            :
            <Navigate to="/login" />
    )

}

export default PrivateRoutes