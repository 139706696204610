import React from "react";
import "./login.css";
import useLogin from '../../hooks/useLogin';
import userIcon from "../../assets/images/login/user.png";
import passwordIcon from "../../assets/images/login/password.png";
const Login = () => {
    const {
        password,
        username,
        error,
        onFormClick,
        onUserChange,
        onPasswordChange,
    } = useLogin();

    return (
        <div className="login">
            <div className="container">
                <div className="d-flex justify-content-center sign-in-panel">
                    <div className="card">
                        <div className="card-header">
                            <h3>Sign In</h3>
                            <h6>{error}</h6>
                        </div>
                        <div className="card-body">
                            <form>
                                <div className="input-group form-group mb-2">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><img src={userIcon} /></span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="username"
                                        value={username}
                                        onChange={onUserChange} />

                                </div>
                                <div className="input-group form-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><img src={passwordIcon} /></span>
                                    </div>
                                    <input type="password" className="form-control" placeholder="password"
                                        value={password}
                                        onChange={onPasswordChange} />
                                </div>
                                <div className="row align-items-center remember">
                                    <input type="checkbox" />Remember Me
                                </div>
                                <div className="form-group">
                                    <button type="button" className="btn float-right login_btn"
                                        onClick={onFormClick}>Login</button>
                                </div>
                            </form>
                        </div>
                        <div className="card-footer">
                            <div className="d-flex justify-content-center links">
                                Don't have an account?<a href="#">Sign Up</a>
                            </div>
                            <div className="d-flex justify-content-center">
                                <a href="#">Forgot your password?</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div></div>

    )

}
export default Login;