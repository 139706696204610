import { useRef, useEffect, useState } from "react";

import { useUserContext } from "./useUserContext";
const useNavSideBar = () => {
    const { state, dispatch } = useUserContext();
    const [activeItem, setActiveItem] = useState(null);
    const [monthList, setMonthList] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [openDropdown, setOpenDropdown] = useState(false);
    const [showMenuBar, setShowMenuBar] = useState(false);
    const [userName, setUserName] = useState('');
    const [profileDropDown, setProfileDropDown] = useState(false);

    const months = [
        'January', 'February', 'March', 'April',
        'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December'
    ];
    const currentMonth = new Date().getMonth(); // 0-indexed (0 for January, 11 for December)
    const currentYear = new Date().getFullYear();
    useEffect(() => {
        setUserName(state.user);
        let j = 11;
        let monthNames = new Array();

        for (let i = currentMonth; i >= 0; i--) {
            monthNames[j] = months[i] + " " + currentYear;
            j--;
        }

        for (let i = 11; i > currentMonth; i--) {
            monthNames[j] = months[i] + " " + (currentYear - 1);
            j--;
        }

        setMonthList(monthNames);
        setSelectedMonth(monthNames[11]);
    }, [])
    useEffect(() => { setOpenDropdown(false); }, [selectedMonth]);
    return {
        userName, activeItem, monthList, selectedMonth, openDropdown, showMenuBar, profileDropDown, setProfileDropDown, setShowMenuBar, setSelectedMonth, setOpenDropdown,
        handleItemClick: (index) => {
            setActiveItem(index === activeItem ? null : index);
        }
        ,
        onlogoutClick: () => {
            localStorage.removeItem('token');
            localStorage.removeItem('username');
            localStorage.removeItem('employee');
            dispatch({ type: 'LOGOUT' });
        }
    }

}
export default useNavSideBar;