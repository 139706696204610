import React, { useReducer } from 'react';
import logo from './logo.svg';
import './App.css';
import Login from './pages/login/login';
import ChangePassword from './pages/ChangePassword/changePassword';
import Home from './pages/home/home';

import './assets/font/font.css';
import PrivateRoutes from './PrivateRoutes';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { UserProvider } from './providers/userProvider';
import { userReducer } from './reducers/userReducer';
import AddLogTime from './pages/log-time/add-log-time.jsx';
import MonthlyCalendar from './pages/monthly-calendars/monthly-calendar.jsx'

const App = () => {

  const initialState = {
    user: null,
    token: null
  };

  const state = useReducer(userReducer, initialState);

  return (<div>
    <Router>
      <UserProvider value={state}>
        <Routes>
          <Route element={<PrivateRoutes />}>

            <Route element={<Home />} path="/" exact>
              <Route element={<AddLogTime />} path="/Add-log-time" />
              <Route element={<MonthlyCalendar />} path="/monthly-calendar/:nbrOfMonth" />
              <Route element={<ChangePassword />} path="/change-password" />
            </Route>

          </Route>

          <Route element={<Login />} path="/login" />

        </Routes></UserProvider>
    </Router>
  </div>
  );
}

export default App;
