import React from "react";
const JobTasksModal = ({ showModal, onCloseModalClick, onSaveAndClose, joblist, onJobTaskSelected, addNewJobTasks, newJobTask, handleNewJobTaskChange }) => {

    return (
        <div className={`modal fade ${showModal ? 'show' : ''}`} tabindex="-1">
            <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Select the tasks you did</h5>
                        <button type="button" className="btn btn-transparent" onClick={() => onSaveAndClose(false)}>X</button>
                    </div>
                    <div className="modal-body">
                        <ul className="list-group">
                            {
                                joblist.map((job) => (
                                    job.id == 0 ? (
                                        <li className="list-group-item ">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" checked={job.checked} onChange={() => onJobTaskSelected(job.id)} />
                                                <label className="form-check-label" for="flexCheckDefault">
                                                    {job.name}
                                                </label>
                                            </div>
                                            {addNewJobTasks && job.id == 0 &&
                                                <textarea
                                                    type="text"
                                                    className="m-3"
                                                    value={newJobTask}
                                                    onChange={(e) => handleNewJobTaskChange(e)}
                                                    placeholder="Add a new task"
                                                    rows="5"
                                                    cols="50"
                                                />
                                            }
                                        </li>
                                    ) : (
                                        <li className={`list-group-item list-group-item-action ${job.checked ? 'active' : ''}`}
                                            onClick={() => onJobTaskSelected(job.id)}>
                                            {job.name}
                                        </li>

                                    )

                                ))
                            }
                        </ul>
                    </div>
                    <div className="modal-footer">
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => onCloseModalClick()}>Clear</button>
                            <button type="button" className="btn btn-primary" onClick={() => onSaveAndClose(false)}>Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
export default JobTasksModal;