import { useEffect, useState } from "react";
import { useUserContext } from "../useUserContext";
import { useParams } from 'react-router-dom';
import { GetMonthlyCalendar } from '../../api/LogTimeApi';
import { GetClient } from '../../api/clientApi';

const useMonthlyCalender = () => {
    const { state, dispatch } = useUserContext();
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const [rows, setRows] = useState([6][7]);
    const [calendar, setCalender] = useState([]);
    const [entries, setEntries] = useState([]);
    const [indexHovered, setIndexHovered] = useState(-1);

    const { nbrOfMonth } = useParams();
    const months = [
        'January', 'February', 'March', 'April',
        'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December'
    ];
    const colors = ['#0F2395', '#9F0447', '#7E5695', '#1D8C5D',
        '#902751', '#55916E', '#8524BC', '#691D96', '#528798', '#20A76A',
        '#8F752D', '#8B5555'];
    useEffect(
        () => {
            console.log("Effect triggered");
            console.log("State token:", nbrOfMonth);
            const selectedMonth = nbrOfMonth;
            const fetchMonthlyEntries = async () => {
                await getMonthlyEntries(selectedMonth);
            }
            fetchMonthlyEntries();

        }, [nbrOfMonth]);
    useEffect(
        () => {

            let month = nbrOfMonth.split('-')[0];
            let year = nbrOfMonth.split('-')[1];

            drawtable(months.indexOf(month), year, []);
            getEntities(months.indexOf(month), year, []);
            setSelectedMonth(months.indexOf(month));
        }, [indexHovered]);
    const getMonthlyEntries = async (selectedMonth) => {
        //let clientData = await GetClient(selectedMonth, state.token);
        var response = await GetMonthlyCalendar(selectedMonth, state.id, state.token);
        var entries = response.map((entry) => ({
            client: entry.client,
            project: entry.project,
            startTime: entry.startTime,
            endTime: entry.endTime,
            date: entry.workDate,
            takeBreak: entry.takeBreak,
            hours: getHourDifference(entry.startTime, entry.endTime, entry.takeBreak),
            tasks: entry.tasks.split(',')
        }))
        let i = 0;
        const coloredEntries = entries.reduce((acc, obj) => {

            const existingItem = acc.find(item => item.client == obj.client && item.project == obj.project);

            if (existingItem) {
                obj.color = existingItem.color; // Use the same color if title already exists
            } else {
                obj.color = i < colors.length ? colors[i] : getRandomColor(); // Assign a new color if title doesn't exist
                i += 1;
            }

            acc.push(obj);
            return acc;
        }, []);
        setEntries(coloredEntries);

    }
    const getRandomColor = () => {
        // This is just a simple function to generate a random color, you can replace it with your logic
        return '#' + Math.floor(Math.random() * 16777215).toString(16);
    }

    const getEntriesOneDate = (day) => {
        let month = nbrOfMonth.split('-')[0];
        let year = nbrOfMonth.split('-')[1];
        const date = new Date(year, months.indexOf(month), day)
        var dayEntries = entries.filter(e => (new Date(e.date).toLocaleDateString()) == date.toLocaleDateString());
        var hours = 0;
        (dayEntries).forEach(element => {
            hours += element.hours;
        });
        return {
            dayEntries,
            day,
            hours
        }

    }

    const getHourDifference = (timeString1, timeString2, takeBreak) => {
        // Convert time strings to Date objects
        const date1 = new Date(`2000-01-01T${timeString1}`);
        const date2 = new Date(`2000-01-01T${timeString2}`);

        // Calculate the difference in milliseconds between the two dates
        const timeDifference = Math.abs(date2 - date1);

        // Convert milliseconds to hours
        const hoursDifference = timeDifference / (1000 * 60 * 60); // 1 hour = 1000 * 60 * 60 milliseconds

        return takeBreak ? hoursDifference - 0.5 : hoursDifference;
    }
    const constaa = (index) => {
        setIndexHovered(index);
    }

    const ShowEntryDetailsOfIndex = (index) => {

    }
    const getEntities = (selectedMonth, year, data) => {
        const daysInMonth = new Date(year, selectedMonth + 1, 0).getDate();
        const firstDayOfMonth = new Date(year, selectedMonth, 1).getDay();
        const calendar = [];
        let day = 1;
        var entries = [];
        // Create the rows for the calendar
        for (let i = 0; i < 5; i++) {
            let row = [];
            // Create the columns for each row
            for (let j = 0; j < 7; j++) {
                if (i === 0 && j < firstDayOfMonth) {
                    row.push(null);
                } else if (day <= daysInMonth) {
                    entries = getEntriesOneDate(day);
                    row.push(entries);
                    day++;
                } else {
                    row.push(null);
                }
            }
            calendar.push(row);
        }
        setCalender(calendar);
    }
    const drawtable = (selectedMonth, year, data) => {
        const daysInMonth = new Date(year, selectedMonth + 1, 0).getDate();
        const firstDayOfMonth = new Date(year, selectedMonth, 1).getDay();
        const calendar = [];
        let day = 1;
        var entries = [];
        // Create the rows for the calendar
        for (let i = 0; i < 5; i++) {
            let row = [];
            // Create the columns for each row
            for (let j = 0; j < 7; j++) {
                if (i === 0 && j < firstDayOfMonth) {
                    row.push(<span className="no-day cell col-sm-1"></span>);
                } else if (day <= daysInMonth) {
                    entries = getEntriesOneDate(day);
                    if (j == 6) {
                        row.push(<span key={`day-${day}`} className="calendar-sunday cell day col-sm-1">
                            <div className="header">
                                <span class="date label label-default pull-right">{((selectedMonth + 1) > 9 ? (selectedMonth + 1) : '0' + (selectedMonth + 1)) + '/' + (day > 9 ? day : '0' + day)}</span>
                                <span class="label hours  label-default pull-left">{entries.hours}</span>
                            </div>
                            <span>
                            </span>
                        </span>);
                    } else {
                        row.push(<span className="day cell col-sm-1">
                            <div className="header">
                                <span class="date label label-default pull-right">{((selectedMonth + 1) > 9 ? (selectedMonth + 1) : '0' + (selectedMonth + 1)) + '/' + (day > 9 ? day : '0' + day)}</span>
                                <span class="label hours pull-left">{entries.hours} hr</span>
                            </div>

                            {entries.dayEntries.map((entry, index) => (
                                <div className="entry" key={index}
                                    onMouseEnter={() => constaa(index)}
                                    onMouseLeave={() => constaa(-1)}>
                                    <span className="label" style={{ backgroundColor: entry.color }}>
                                        <span className="hours">
                                            {entry.hours} h
                                        </span>
                                        <span className="fields">
                                            {`${entry.client} - ${entry.project}`}
                                        </span>

                                    </span>
                                    {indexHovered == index &&
                                        <span className="fields">
                                            {entry.tasks}
                                        </span>
                                    }
                                </div >
                            ))}


                        </span >



                        );
                    }
                    day++;
                } else {
                    row.push(<span className="no-day cell col-sm-1"></span>);
                }
            }
            calendar.push(<div className="row week">{row}</div>);
        }
        setRows(calendar);
    }
    useEffect(() => {
        let month = nbrOfMonth.split('-')[0];
        let year = nbrOfMonth.split('-')[1];

        drawtable(months.indexOf(month), year, []);
        getEntities(months.indexOf(month), year, []);
        setSelectedMonth(months.indexOf(month));
    }, [entries])
    return {
        selectedMonth,
        rows,
        calendar,


    }
}
export default useMonthlyCalender;