import { useRef, useEffect, useState } from "react";
import { useUserContext } from "../useUserContext";
import { GetAllClients } from '../../api/clientApi';
import { GetAllProjects } from '../../api/projectApi';
import { GetAllTeamLeader } from '../../api/employeeApi';
import { GetAllWorkers } from '../../api/employeeApi';
import { GetAllJobTasks, LogTime } from '../../api/LogTimeApi';
import moment from 'moment';

const useAddLogTime = () => {
    const { state, dispatch } = useUserContext();
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState('');
    const [projects, setProjects] = useState([]);
    const [displayProjects, setDisplayProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState('');
    const [isTeamLeader, setIsTeamLeader] = useState(false);
    const [workDate, setWorkDate] = useState(new Date());
    const [startTime, setStartTime] = useState('06:00');
    const [endTime, setEndTime] = useState('06:30');
    const [jobTasks, setJobTasks] = useState([]);
    const [numberOfHours, setNumberOfHours] = useState(0);
    const [addNewJobTasks, setAddNewJobTasks] = useState(false);
    const [newJobTask, setNewJobTasks] = useState('');
    const [showJobTasksModal, setShowJobTasksModal] = useState(false);
    const [startTimes, setStartTimes] = useState([]);
    const [endTimes, setEndTimes] = useState([]);
    const [teamLeaders, setTeamLeaders] = useState([]);
    const [selectedTteamLeader, setSelectedTteamLeader] = useState([]);
    const [allWorkers, setAllWorkers] = useState([]);
    const [selectedWorkers, setSelectedWorkers] = useState([]);
    const [takeBreak, setTakeBreak] = useState(true);
    const [note, setNote] = useState('');
    const [showAlarm, setShowAlarm] = useState(false);
    const [alarmMessage, setAlarmMessage] = useState({ type: '', text: '' });

    // Function to generate time options
    const generateTimes = () => {
        const times = [];
        for (let i = 0; i < 48; i++) {
            const hour = Math.floor(i / 2);
            const minutes = i % 2 === 0 ? '00' : '30';
            const time = `${String(hour).padStart(2, '0')}:${minutes}`;
            times.push(time);
        }
        return times;
    };
    useEffect(() => {
        if (selectedTteamLeader == state.id) {
            setSelectedWorkers([]);
            setIsTeamLeader(true);
        } else {
            setSelectedWorkers([]);
            setIsTeamLeader(false);
        }
    }, [selectedTteamLeader])
    useEffect(() => {

        const times = generateTimes();
        setStartTimes(times);
        setEndTimes(times);

        const fetchWorkers = async () => {
            if (Object.keys(clients).length === 0) {
                await fetchAllWorkers();
            }
        }
        const fetchClients = async () => {
            if (Object.keys(clients).length === 0) {
                await fetchAllClients();
            }
        }
        const fetchProjects = async () => {
            if (Object.keys(projects).length === 0) {
                await fetchAllProjects();
            }
        }
        const fetchJobTasks = async () => {
            if (Object.keys(jobTasks).length === 0) {
                await fetchAllJobTasks();
            }
        }
        const fetchTeamLeaders = async () => {
            if (Object.keys(teamLeaders).length === 0) {
                await fetchAllTeamLeaders();
            }
        }
        fetchJobTasks()
        fetchProjects();
        fetchClients();
        fetchTeamLeaders();
        fetchWorkers();
    }, []);
    const fetchAllTeamLeaders = async () => {
        var response = await GetAllTeamLeader(state.token);
        var teamLeaders = [{
            id: state.id,
            name: "Me"
        }, ...response.filter(item => {

            if (item.id != state.id) {
                return item;
            }

        }).map(leader => (
            {
                id: leader.id,
                name: leader.firstName + " " + leader.lastName
            }
        ))];

        setTeamLeaders(teamLeaders);
        setSelectedTteamLeader(teamLeaders[0].id);
    }
    const fetchAllWorkers = async () => {
        var response = await GetAllWorkers(state.token);
        var workers = (response.filter(item => (item.id != state.id))).map(worker => (
            {
                id: worker.id,
                name: worker.firstName + " " + worker.lastName
            }
        ));
        setAllWorkers(workers);
    }
    useEffect(() => {
        const start = new Date(`January 1, 2024 ${startTime}`);
        const end = new Date(`January 1, 2024 ${endTime}`);
        const diff = (end.getTime() - start.getTime()) / (1000 * 60 * 60); // Convert milliseconds to hours
        setNumberOfHours(takeBreak ? (diff - 0.5) : diff);

    }, [startTime, endTime, takeBreak]);
    useEffect(() => {
        if (Object.keys(projects).length != 0 && selectedClient != "") {

            let newprojecList = (projects.filter(item => (item.clientId.toLocaleLowerCase() == selectedClient.toLocaleLowerCase()))).map(item => ({
                id: item.id,
                name: item.name,
            }));
            if (newprojecList.length == 0) {
                newprojecList = [{
                    id: 0,
                    name: 'No projects',
                }]
            }
            setSelectedProject(newprojecList[0].id)
            setDisplayProjects(newprojecList);
        }

    }, [selectedClient]);

    const fetchAllProjects = async () => {
        var response = await GetAllProjects(state.token);

        setProjects(response);

    }
    const fetchAllClients = async () => {
        var response = await GetAllClients(state.token);
        let clients = await response.map(item => ({
            id: item.id,
            name: item.name
        }));
        setClients(clients);
        setSelectedClient(clients[0].id);

    }
    const updateTimeIntervals = (start) => {
        const startIndex = startTimes.indexOf(start);
        setEndTimes(startTimes.slice(startIndex + 1));
        setEndTime(startTimes[startIndex + 1]);
    };
    const fetchAllJobTasks = async () => {
        var response = await GetAllJobTasks(state.token);
        var jobTasks = response.map(item => ({
            id: item.id,
            name: item.name,
            checked: false
        }))
        jobTasks.push({
            id: 0,
            name: 'Other',
            checked: false
        })
        setJobTasks(jobTasks);
    }
    const validation = () => {
        if (selectedProject == '' || selectedProject == 0) return false;
        //if (isTeamLeader && jobTasks.filter(job => job.checked == true).length == 0) return false;
        if (workDate == '') return false;
        if (numberOfHours == 0) return false;
        return true;
    }
    const convertToCorrectFormat = (originalDateString) => {
        // Try parsing the date in the expected format
        const expectedFormat = "MM/DD/YYYY";
        const parsedDate = moment(originalDateString, expectedFormat, true);


        // If parsing fails, assume the alternative format
        if (!parsedDate.isValid()) {
            const alternativeFormat = "DD/MM/YYYY";
            return moment(originalDateString, alternativeFormat).format(expectedFormat);
        }


        // Date was successfully parsed in the expected format
        return parsedDate.format(expectedFormat);
    }
    function parseDateWithoutTime(dateString) {
        const parsedDate = new Date(dateString);
        parsedDate.setHours(0, 0, 0, 0);
        return parsedDate;
    }
    const clearForm = () => {
        setSelectedClient(clients[0].id);
        setIsTeamLeader(true);
        setWorkDate(new Date());
        setStartTime('06:00');
        setEndTime('06:30');
        setAddNewJobTasks(false);
        setNewJobTasks('');
        setShowJobTasksModal(false);
        setSelectedWorkers([]);
        var newJobTasks = jobTasks.map((task) => ({ ...task, checked: false }));
        setJobTasks(newJobTasks);
    }
    return {
        clients, selectedClient, displayProjects, selectedProject,
        isTeamLeader, workDate, startTime, endTime, startTimes,
        endTimes, showJobTasksModal, jobTasks, addNewJobTasks, newJobTask,
        numberOfHours, teamLeaders, selectedTteamLeader, allWorkers, selectedWorkers, takeBreak, note, showAlarm, alarmMessage, setTakeBreak,
        setSelectedClient, setSelectedProject, setIsTeamLeader, setWorkDate, setShowJobTasksModal, setSelectedTteamLeader, setSelectedWorkers,
        handleStartTimeChange: (start) => {
            setStartTime(start);
            updateTimeIntervals(start);
        },
        handleEndTimeChange: (end) => {
            setEndTime(end);
            // Check if selected end time is less than selected start time
            if (startTime > end) {
                setStartTime('');
            }
        },
        onJobTasksSelected: (id) => {
            if (id == 0) {
                var addNewTask = jobTasks.filter(task => task.id == id)[0].checked;

                if (addNewTask) {
                    setAddNewJobTasks(false);
                    setNewJobTasks('');
                } else {
                    setAddNewJobTasks(true);
                    setNewJobTasks('');
                }
            }
            let oldJobTasks = jobTasks.map((task) => (id === task.id ? { ...task, checked: !task.checked } : task))
            setJobTasks(oldJobTasks);

        },
        handleNoteChange: (event) => {
            event.preventDefault();
            const value = event.target.value;
            setNote(value);
        },

        onClearJobTasks: () => {
            var newJobTasks = jobTasks.map((task) => ({ ...task, checked: false }));
            setJobTasks(newJobTasks);
            setAddNewJobTasks(false);
        },

        handleNewJobTaskChange: (event) => {
            event.preventDefault();
            const value = event.target.value;
            setNewJobTasks(value);
        },

        onhandleClear: (event) => {
            event.preventDefault();
            clearForm();
        },
        onSubmitClick: async (event) => {
            event.preventDefault();
            if (validation()) {
                var selectedJobTasks = jobTasks.filter(job => job.checked == true);
                let tasks = '';
                selectedJobTasks.forEach(element => {
                    if (element.name == 'Other') {
                        tasks += element.name + ':' + newJobTask;
                    } else {
                        tasks += element.name + ',';
                    }
                });
                let param = {
                    Employee: state.id,
                    TeamLeader: isTeamLeader ? state.id : selectedTteamLeader,
                    Project: selectedProject,
                    Workers: selectedWorkers.map(worker => (worker.id)),
                    WorkDate: convertToCorrectFormat(workDate).toString(), // Short date format without time                    ,
                    StartTime: startTime,
                    EndTime: endTime,
                    TakeBreak: takeBreak,
                    Tasks: tasks,
                    Notes: note

                };
                console.log(param);
                var response = await LogTime(param, state.token);
                if (response.status === 200 || response.status === 201) {
                    // Do something if successful
                    setShowAlarm(true);
                    setAlarmMessage({ type: "success", text: 'Time is logged successfully' })
                    clearForm();
                    setTimeout(() => { setShowAlarm(false) }, 5000);

                } else {
                    setShowAlarm(true);
                    setAlarmMessage({ type: 'danger', text: 'Failed to log time check and try again' })
                }
            } else {
                setShowAlarm(true);
                setAlarmMessage({ type: 'danger', text: 'Some fields are empty.' })
            }

        }
    }
}
export default useAddLogTime;