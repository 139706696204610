import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import NavSideBar from "../../Common/NavSideBar";

import './home.css';
const Home = () => {
    return (
        <div className="container">

            <div className="row">
                <div className="col-xl-12 navbar-panal">
                    <NavSideBar />
                </div>
                <div className="col-xl-12 col-lg-12 col-md-10 col-12 main-page">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}
export default Home;