import React, { useState } from 'react';

import useMonthlyCalender from '../../hooks/monthly-calendar/useMonthlyCalender';
import './monthly-calendar.css';
import Day from './day';
const MonthlyCalendar = () => {
    const { selectedMonth, rows, calendar } = useMonthlyCalender();
    return (
        <div className="calander">
            <div className="row weekday d-none  d-lg-flex ">
                <span className='cell  col-sm-1'>Sunday</span>
                <span className='cell  col-sm-1'>Monday</span>
                <span className='cell  col-sm-1'>Tuesday</span>
                <span className='cell  col-sm-1'>Wednesday</span>
                <span className='cell  col-sm-1'>Thursday</span>
                <span className='cell  col-sm-1'>Friday</span>
                <span className='cell  col-sm-1'>Saturday</span>

            </div>
            {
                calendar.map((row) => {
                    return (
                        <div className="row week">
                            {row.map((day, index) => (
                                <span key={index} className={`${index == 0 && day != null ? 'calendar-sunday ' : ''}${day == null ? 'no-day  d-none d-lg-block ' : 'day'} cell  col-12 col-lg-12`}>

                                    <Day day={day} selectedMonth={selectedMonth} />
                                </span>
                            ))}

                        </div>

                    )
                })
            };

        </div >
    )
};

export default MonthlyCalendar;