import React, { useState } from 'react';

const DayEntity = ({ entry }) => {
    const [entityHovered, setEntityHoevered] = useState(false);

    return (<div className="entry"
        onMouseEnter={() => setEntityHoevered(true)}
        onMouseLeave={() => setEntityHoevered(false)}>
        <span className="label" style={{ backgroundColor: entry.color }}>
            <span className="hours">
                {entry.hours} h
            </span>
            <span className="fields">
                {`${entry.project}`}
            </span>

        </span>
        {entityHovered &&
            <span className="tooltip">
                <div class="tooltip-arrow"></div>
                {entry.notes}
            </span>
        }
    </div >);
}
export default DayEntity;