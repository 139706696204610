import { useState } from "react";
import { useUserContext } from "./useUserContext";
import { ChangePassword } from "../api/accountApi";
import { useNavigate } from "react-router-dom";
const useChangePassword = () => {
    const { state, dispatch } = useUserContext();
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [username, setUsername] = useState(state.user);
    const [passwordError, setPasswordError] = useState('');

    const history = useNavigate();
    const validatePassword = (password) => {
        // Regular expression to match the criteria: minimum 8 characters, one uppercase, one lowercase, one number, and one special character
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return regex.test(password);
    };
    return {
        username, currentPassword, newPassword, confirmPassword, passwordError,
        onFormClick: async () => {
            // Check if the new password meets the criteria
            const isValidPassword = validatePassword(newPassword);
            // Check if the new password matches the confirmation password
            const isMatched = newPassword === confirmPassword;
            if (currentPassword.length == 0) {
                setPasswordError('Current password should not be empty ');
                setIsValid(false);
            } else if (!isValidPassword) {
                setPasswordError(
                    'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.'
                );
                setIsValid(false);
            } else if (!isMatched) {
                setPasswordError('New password and confirm password should match.');
                setIsValid(false);
            } else {
                setPasswordError('');
                setIsValid(false);
            }

            // You might want to add further validation, such as checking if the current password matches the stored one
            console.log(isValid);
            // Update the isValid state based on all validation criteria
            if (isValid) {

                var result = await ChangePassword({ Username: state.user, CurrentPassword: currentPassword, NewPassword: currentPassword }, state.token);
                var userInfo = result.data;
                if (userInfo) {

                    if (userInfo.passwordUpdated) {
                        dispatch({
                            type: 'LOGIN',
                            user: userInfo.username || null,
                            token: userInfo.token || null,
                            id: userInfo.id || null,
                            updatePassword: false,

                        });
                        var monthNames = ["January", "February", "March", "April", "May", "June",
                            "July", "August", "September", "October", "November", "December"];
                        let nbrOfMonth = monthNames[new Date().getMonth()] + '-' + new Date().getFullYear();
                        history("/" + (state.redirect ? state.redirect : "monthly-calendar/" + nbrOfMonth));
                    } else {

                        dispatch({
                            type: 'ChangePassowrd',
                            user: userInfo.username || null,
                            token: userInfo.token || null,
                            id: userInfo.id || null,
                            updatePassword: true
                        });
                    }


                } else {
                    setPasswordError('The password is not updated');
                    setCurrentPassword('');
                    setNewPassword('');
                    setConfirmPassword('');
                }
            }
        },
        onConfirmPassword: (e) => {
            e.preventDefault();
            setConfirmPassword(e.target.value);
        },
        onNewPasswordChange: (e) => {
            e.preventDefault();
            setNewPassword(e.target.value);
        },
        onCurrentPasswordChange: (e) => {
            e.preventDefault();
            setCurrentPassword(e.target.value);
        },
        onClear: () => {
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');

        },

    }
}


export default useChangePassword;