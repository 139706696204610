export const userReducer = (state, action) => {
  switch (action.type) {
    case 'AddItem':
      return {
        redirect: action.redirect
      }
    case 'LOGIN_SUCCESS':
      return {
        user: action.user,
        token: action.token,
        id: action.id,
        updatePassword: action.updatePassword || null,

      };
    case 'LOGOUT':
      return {
        user: null,
        token: null,
        id: null,
      }
    case 'CHANGEPASSWORD':
      return {
        user: action.user || null,
        token: action.token || null,
        updatePassword: action.updatePassword || null,
      }


    default:
      throw new Error('Unexpected action');
  }
};
