import React, { useState } from 'react';
import DayEntityList from './day-entityList';
const Day = ({ day, selectedMonth }) => {
    if (day) {
        return (
            <>

                <div className="header">
                    <span class="date label label-default pull-right">{((selectedMonth + 1) > 9 ? (selectedMonth + 1) : '0' + (selectedMonth + 1)) + '/' + (day.day > 9 ? day.day : '0' + day.day)}</span>
                    <span class="label hours pull-left">{day.hours} hr</span>
                </div>
                <DayEntityList entities={day.dayEntries || null}
                />
            </>



        )


    }
}
export default Day;