import React, { useState } from 'react';
import DayEntity from './day-entity';
const DayEntityList = ({ entities }) => {
    if (entities)
        return (
            entities.map((entity, index) =>
                <DayEntity key={index} entry={entity} />


            ))

}
export default DayEntityList;
